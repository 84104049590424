<template>
  <div class="remove-container">
    <div class="result-card">
      <image-filter modelName="ImageFilter" :modelFilepath="modelFilepath" :imageSize="276" :warmupModel="warmupModel"
        :preprocess="preprocess" :postprocess="postprocess" :currentFileList="currentFileList" />
    </div>
  </div>
</template>

<script setup>
import ImageFilter from "../../components/models/imageFilter.vue";
import { ref } from "vue";
import { useMeta } from 'vue-meta'
useMeta({
  title: 'Use Photo Effects and Filters to turn pics into masterpieces - Boolv.Toolkit',
  description: "Get the full thumbnails of all the filters with just one click, with a wide range of photo filters and photo effects to choose from, allowing you to transform an ordinary photo into artwork.",
  htmlAttrs: { lang: 'en', amp: true },
})
</script>

<style lang="less">

</style>
