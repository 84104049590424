<template>
  <div>
    <!-- 模型加载loading -->
    <model-status v-if="modelLoading || modelInitializing" :modelLoading="modelLoading"
      :modelInitializing="modelInitializing"></model-status>
    <div class="back-ground-remover-wrap" :style="{ 'padding-top': imageHandleComplete ? '48px' : '180px' }">
      <h1 class="tool-title" :style="{ 'margin-bottom': imageHandleComplete ? '30px' : '52px' }">
        Photo Effects & Filters
      </h1>
      <!-- 上传组件 -->
      <div class="common-upload-box">
        <common-upload @changFileList="changFileList" @changSelectImage="changSelectImage" :imageUrls="imageUrls"
          :modelLoading="modelLoading" :modelInitializing="modelInitializing" :modelLoadingError="modelLoadingError"
          :webcamEnabled="webcamEnabled" :modelInProgress="modelInProgress" :imageHandleComplete="imageHandleComplete"
          :changeImage="changeImage" :uploadPlaceholder="uploadPlaceholder" :percentSpeedTime="30" />
      </div>

      <!-- 图片滤镜 -->
      <div class="imageFilter-container" :style="{
        opacity: imageHandleComplete ? 1 : 0,
        marginTop: imageHandleComplete ? '30px' : '-200px',
        pointerEvents: imageHandleComplete ? 'all' : 'none'
      }">
        <div class="canvas-show-container position-box" @mouseenter="handleMattingBoxMouseEnter"
          @mouseleave="handleMattingBoxMouseLeave">
          <canvas id="previewer"></canvas>
          <div class="buttons-container" v-if="showMattingEditBtns">
            <a-button type="primary" @click="downloadImage()">Download</a-button>
          </div>
        </div>

        <div class="imageFilter-preview-container">
          <ul class="canvas-show-ul">
            <li class="canvas-show-li" v-for="(filter, index) in imageUrlConfig" :key="filter.id" v-show="index != 0"
              @click="handlePreviewClick(filter.id, index)">
              <div class="canvas-show-hover" v-if="currentSelectItem === index"></div>
              <img v-if="filter.imgUrl" :src="filter.imgUrl" width="76" :height="rightImageHeight"
                class="canvas-show-image" />
            </li>
            <div class="placeholder-box"></div>
            <div class="placeholder-box"></div>
            <div class="placeholder-box"></div>
          </ul>
          <canvas id="render" v-show="false"></canvas>
        </div>
        <common-rate @changeRate="handleMattingChangeRate" :clearRate="clearRate" ref="rate" />
      </div>
    </div>
  </div>
</template>

<script setup>
import ModelStatus from "../modelStatus.vue";
import { ref, defineProps, onMounted, reactive } from "vue";
import CommonUpload from "../commonUpload.vue";
import { filterDownload } from "../../utils/buriedFunction/filter/filter-download.js";
import { filterEmoji } from "../../utils/buriedFunction/filter/filter-emoji.js";
import { filterPreview } from "../../utils/buriedFunction/filter/filter-preview.js";
import filterPainter from "../../utils/webglRender/filterPainter.js";
import { filterConfig } from "../../utils/webglRender/imageFilter.js";
import CommonRate from "../common-rate.vue";
import filterIcon1 from '../../assets/example-images/filter_icon_1.webp'
import filterIcon2 from '../../assets/example-images/filter_icon_2.webp'
import filterIcon3 from '../../assets/example-images/filter_icon_3.webp'
import filterSrc1 from '../../assets/example-images/filter_src_1.webp'
import filterSrc2 from '../../assets/example-images/filter_src_2.webp'
import filterSrc3 from '../../assets/example-images/filter_src_3.webp'
import messageClose from '../../assets/message_close.svg'
import { LoadingOutlined } from '@ant-design/icons-vue';

import dataUtils from "@/utils/util.js";
const props = defineProps({});
var md5 = require("md5");
const imageUrls = ref([
  {
    id: 1,
    iconSrc: filterIcon1,
    src: filterSrc1,
  },
  {
    id: 2,
    iconSrc: filterIcon2,
    src: filterSrc2,
  }, {
    id: 3,
    iconSrc: filterIcon3,
    src: filterSrc3,
  }
])
const visible = ref(true)
const uploadPlaceholder = ref('')
const currentImgUrl = ref("");
const currentMattingSelectRate = ref({});
const imageHandleComplete = ref(false);
const showMattingEditBtns = ref(false);
const canvasWidth = ref(522);
const canvasHeight = ref(522);
const imgMultiple = ref(1);
const modelLoading = ref(false);
const changeImage = ref(false);
const modelInProgress = ref('start');
const currentSelectItem = ref(0);
const imageUrlConfig = ref(filterConfig);
const canvasNum = ref(0);
const currentFilterId = ref(-1);
const clearRate = ref(false)
const rate = ref(null);
const rightImageHeight = ref(76)
const customDialogStyle = reactive({
  top: '500px'
})

onMounted(() => {
  //预加载lut图
  filterConfig.forEach(ele => {
    if (ele.lut) {
      preloadImage(ele.lut)
    }
  });

})

const preloadImage = (url) => {
  var img = new Image();
  img.src = url;
}

const renderLiCanvas = (filterConfig, url, index) => {
  if (!filterConfig || !filterConfig.length) {
    return;
  }
  // if (index == 0) return

  //在渲染右侧 预览的时候去分批次加载所有的滤镜，加载完毕后截图，删除这个webgl上下文
  const filter = filterConfig[index];
  // console.log(`[ 准备开始${filter.id}的渲染任务] >`);
  filterPainter(
    `#${filter.id}`,
    76,
    76,
    filter.vertexShader,
    filter.fragmentShader,
    url,
    filter.lut,
    false,
    webglLoadDone,
    true
  );
};

const changSelectImage = (url) => {
  clearCanvas()
  modelInProgress.value = 'progressing';
  currentImgUrl.value = url;
  renderLiCanvas(filterConfig, url, 0);
  if (imageHandleComplete.value) {
    imageHandleComplete.value = false;
  }
};

const handlePreviewClick = (id, index) => {
  filterPreview("toolkits_effect_preview", id);
  currentSelectItem.value = index;
  drowAfterLoadImage(currentImgUrl.value, index + 1, false, false);
};

const changFileList = (fileList) => {
  if (!fileList || !fileList.length) {
    return;
  }
  rate.value.handleClearRate()
  clearCanvas();
  modelInProgress.value = 'progressing';
  const imgUrl = URL.createObjectURL(
    fileList[fileList.length - 1].originFileObj
  );
  currentImgUrl.value = imgUrl;
  canvasNum.value = 0;
  currentSelectItem.value = 0

  // drowAfterLoadImage(imgUrl);
  renderLiCanvas(filterConfig, imgUrl, 0);
  if (imageHandleComplete.value) {
    changeImage.value = true;
    imageHandleComplete.value = false;
  }
};

const downloadImage = () => {
  visible.value = true
  if (currentFilterId.value) {
    const filterId = `filter${currentFilterId.value - 1}`;
    filterDownload("toolkits_effect_download ", filterId);
  }

  var link = document.createElement("a");
  link.download = `photo-effects-${md5(Date.now())}`;
  link.href = document.getElementById("previewer").toDataURL();
  link.click();
};

const handleMattingChangeRate = (item) => {
  if (currentFilterId.value) {
    const filterId = `filter${currentFilterId.value - 1}`;
    filterEmoji("toolkits_effect_emoji", item.id, filterId);
  }

  currentMattingSelectRate.value = item;
};


const handleMattingBoxMouseEnter = () => {
  showMattingEditBtns.value = true;
};

const handleMattingBoxMouseLeave = () => {
  showMattingEditBtns.value = false;
};

const getImageFromCanvas = (filterId) => {
  const canvas = document.getElementById("render");
  const dataURL = canvas.toDataURL();
  imageUrlConfig.value.forEach((filter) => {
    if (filterId == filter.id) {
      // console.log(filter)
      filter.imgUrl = dataURL;
      // document.getElementById(filterId).remove();
      // console.log(`[ 删除${filterId}的上下文，开始渲染下一个]>`);
    }

  });
};

const webglLoadDone = (id) => {
  // console.log(`[ 完成${id}的渲染任务，开始截图 ] >`);
  const filterId = id.replace("#", "");
  //截图 删除上下文
  getImageFromCanvas(filterId);
  // if (canvasNum.value == filterConfig.length - 1) {
  // console.log("全部加载完毕");
  if (canvasNum.value == 18) {
    drowAfterLoadImage(currentImgUrl.value);
    imageHandleComplete.value = true;
    modelInProgress.value = 'done';
    uploadPlaceholder.value = 'Change an image here'
  }
  const index = canvasNum.value;
  setTimeout(() => {
    renderLiCanvas(filterConfig, currentImgUrl.value, index + 1);
  }, 0);

  canvasNum.value += 1;
};

const drowAfterLoadImage = (url, index, isClear, isRenderList) => {
  let img = new Image();
  img.crossOrigin = "anonymous";
  const filterIndex = index ? index - 1 : 0;
  const clear = isClear === false ? false : true;
  img.onload = function () {
    const { imgWidth, imgHeight } = showImage(img);
    canvasWidth.value = imgWidth;
    canvasHeight.value = imgHeight;
    rightImageHeight.value = (imgHeight * 76) / imgWidth
    currentImgUrl.value = url;
    currentFilterId.value = index;
    filterPainter(
      "#previewer",
      imgWidth,
      imgHeight,
      filterConfig[filterIndex].vertexShader,
      filterConfig[filterIndex].fragmentShader,
      url,
      filterConfig[filterIndex].lut,
      clear,
      webglLoadDone,
      false
    );


  };
  img.src = url;
};

// 图片缩放
const showImage = (image) => {
  if (image.width > 522 || image.height > 522) {
    // 长 > 宽
    if (522 / image.width > 522 / image.height) {
      if (522 / image.width < 1 && 522 / image.height < 1) {
        imgMultiple.value = 522 / image.height;
      } else if (522 / image.width > 1 && 522 / image.height < 1) {
        imgMultiple.value = 522 / image.height;
      }
    } else if (522 / image.width < 522 / image.height) {
      // 宽 > 长
      if (522 / image.width < 1 && 522 / image.height < 1) {
        imgMultiple.value = 522 / image.width;
      } else if (522 / image.width < 1 && 522 / image.height > 1) {
        imgMultiple.value = 522 / image.width;
      }
    } else {
      imgMultiple.value = 522 / image.width;
    }
  } else if (image.width < 522 && image.height < 522) {
    if (522 / image.width > 522 / image.height) {
      imgMultiple.value = 522 / image.height;
    } else if (522 / image.width < 522 / image.height) {
      imgMultiple.value = 522 / image.width;
    } else {
      imgMultiple.value = 1;
    }
  }
  const imgWidth = imgMultiple.value * image.width;
  const imgHeight = imgMultiple.value * image.height;
  const imgX = (522 - imgWidth) / 2;
  const imgY = (522 - imgHeight) / 2;

  return {
    imgX,
    imgY,
    imgWidth,
    imgHeight,
  };
};

// 清除画布
const clearCanvas = () => {
  modelInProgress.value = 'start';
};


</script>

<style lang="less" scoped>
.dialogClass {
  top: 500px
}

.backGroundRemover-container {
  width: 100%;
  height: 452px;
  margin-top: 82px;
  display: flex;
  justify-content: center;
}

.back-ground-remover-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  margin-right: 118px;
}

.tool-title {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #000000;
  margin-bottom: 52px;
}

.imageFilter-container {
  margin-top: 48px;
  width: 100%;
  position: relative;
}

.common-upload-box {
  width: 604px;
  height: 92px;
  border: 2px dashed #878787;
  border-radius: 6px;
}

.canvas-show-hover {
  position: absolute;
  width: 76px;
  height: 76px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.45);
}

.canvas-show-container {
  margin: 0 auto;
  text-align: center;
  background: #f7f7f7;
  width: 522px;
  height: 522px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageFilter-preview-container {
  position: absolute;
  top: 0px;
  right: 100px;
}

.canvas-show-ul {
  list-style-type: none;
  width: 320px;
  height: 522px;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.canvas-show-ul::-webkit-scrollbar {
  background-color: transparent;
  /* or add it to the track */
  width: 7px;
}

.canvas-show-ul::-webkit-scrollbar-thumb {
  background: rgba(31, 35, 41, 0.3);
  border-radius: 3.5px;
  width: 7px !important;
  height: 135px !important;
}

.canvas-show-ul::-webkit-scrollbar-track {
  background: transparent;
}

.canvas-show-li {
  width: 76px;
  height: 76px;
  background: #ededed;
  border-radius: 6px;
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 12px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.canvas-show-li:nth-last-of-type(-n+3) {
  margin-bottom: 0;
}

.canvas-show-image {
  -webkit-user-drag: none;
}

.canvas-show-li:hover {
  cursor: pointer;
}

.canvas-show-li canvas {
  border-radius: 4px;
}

.placeholder-box {
  width: 76px;
  display: inline-block;
  height: 1px;
  margin-right: 12px;
}

.position-box {
  position: relative;

  .buttons-container {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 80px 14px 80px;
    display: flex;
    justify-content: center;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;

    .ant-btn {
      width: 120px;
      height: 36px;
      border-radius: 200px;
      color: #fff;
      border: none;
    }

    .ant-btn-default {
      background: rgba(0, 0, 0, 0.8);
      opacity: 0.8;
    }

    .ant-btn-primary {
      opacity: 1;
      background: #875eff;
    }
  }

  @keyframes fadenum {
    0% {
      opacity: 0;
      transform: scaleX(0.5);
    }

    100% {
      opacity: 1;
      transform: scaleX(1);
    }
  }
}

.scroll-area {
  position: relative;
  margin: auto;
  width: 600px;
  height: 400px;
}
</style>
